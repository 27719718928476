import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { MainFailed } from "page_components/order_confirmation"

const Account = () => {
  const title = "Niepowodzenie płatności"

  return (
    <Layout
      seo={{
        title: title,
      }}
      isOrder
    >
      <Breadcrumbs title={title} />
      <PageHeader title="Niepowodzenie" />
      <MainFailed />
    </Layout>
  )
}

export default Account
